body {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    font: 12pt;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 1240px;
    min-height: auto;
    padding: 0 20px !important;
    font-weight: 500;
    margin: 0 auto !important;
    border-radius: 5px;
    background: #ffffff;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

.print_border {
    border-bottom: 2px solid #a64d79;
    font-family: "open sans";
}

.print_table {
    width: 100%;
    font-size: 12px;
    border-style: none;
    border-collapse: collapse;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.print_table1 {
    font-size: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.print_table1 {
    width: 100%;
    border-collapse: collapse;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.print-table1,
.table_th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
}

.print_table1,
.table_td {
    padding: 5px;
    border: 1px solid #dddddd;
}

.print-hr {
    border-top: 1px solid #d4d4d4;
}

.table_head {
    font-family: "open sans";
    border-bottom: 2px solid #A64D79;
    text-align: center;
    font-weight: 500;
    border-top: none;
    border-left: none;
    border-right: none;

}

.table_body {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    font-family: "open sans";
    text-align: center;
}

@page {
    /* size: A4; */
    margin: 2px !important;
    /* margin-top: 0px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0; */

}

@media print {
    body {
        margin-top: 0px;
        padding-top: 0px;
        box-shadow: 0;
        height: auto !important;
        zoom: 75%;
        color: black;
        overflow: hidden !important;
    }

    .page {
        display: block;
        color: black;
    }

    .noprint {
        display: none !important;
        height: 0px !important;
    }
}