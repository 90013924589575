@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

.error-boundary {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: auto;
}

.text {
    text-align: center;
    padding-top: 45px;
    top: 20%;
    width: 100%;
    margin: auto;
    font-family: "Poppins";
}

.zc_table2 {
    font-size: 10px;
    font-family: "Poppins";
}

body {
    font-family: "Poppins";
}

* {
    font-family: "Poppins";
    margin: 0px;
    padding: 0px;
}

.overlay {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    font-family: "Poppins";
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.textOverlay {
    position: absolute;
    top: 60%;
    z-index: 999;
    font-family: "Poppins";
    left: 50%;
    font-size: 12px;
    color: #043c64;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
    background-color: rgba(128, 169, 199, 0.767) !important;
    width: 4px !important;
    height: 10px;
    border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #043c64e0 !important;
    border-radius: 5px !important;
}

.zc-tab-header-title {
    display: "flex";
    padding: 0;
    margin: 0;
    justify-content: "space-between";
    background: "#043c64";
    border-top-right-radius: 3;
    border-top-left-radius: 3;
}

/* Padding */
.zc-p-1 {
    padding: 4px;
}

.zc-p-2 {
    padding: 8px;
}

.zc-p-3 {
    padding: 12px;
}

.zc-p-4 {
    padding: 16px;
}

.zc-p-5 {
    padding: 20px;
}

/* Padding top */
.zc-pt-1 {
    padding-top: 4px;
}

.zc-pt-2 {
    padding-top: 8px;
}

.zc-pt-3 {
    padding-top: 12px;
}

.zc-pt-4 {
    padding-top: 16px;
}

.zc-pt-5 {
    padding-top: 20px;
}

/* Padding Bottom */
.zc-pb-1 {
    padding-bottom: 4px;
}

.zc-pb-2 {
    padding-bottom: 8px;
}

.zc-pb-3 {
    padding-bottom: 12px;
}

.zc-pb-4 {
    padding-bottom: 16px;
}

.zc-pb-5 {
    padding-bottom: 20px;
}

/* Padding Left */
.zc-pl-1 {
    padding-left: 4px;
}

.zc-pl-2 {
    padding-left: 8px;
}

.zc-pl-3 {
    padding-left: 12px;
}

.zc-pl-4 {
    padding-left: 16px;
}

.zc-pl-5 {
    padding-left: 20px;
}

/* Padding Right */
.zc-pr-1 {
    padding-right: 4px;
}

.zc-pr-2 {
    padding-right: 8px;
}

.zc-pr-3 {
    padding-right: 12px;
}

.zc-pr-4 {
    padding-right: 16px;
}

.zc-pr-5 {
    padding-right: 20px;
}

/* Margin */
.zc-m-1 {
    margin: 4px;
}

.zc-m-2 {
    margin: 8px;
}

.zc-m-3 {
    margin: 12px;
}

.zc-m-4 {
    margin: 16px;
}

.zc-m-5 {
    margin: 20px;
}

/* Padding top */
.zc-mt-1 {
    margin-top: 4px;
}

.zc-mt-2 {
    margin-top: 8px;
}

.zc-mt-3 {
    margin-top: 12px;
}

.zc-m-2 {
    margin-top: 8px;
}

.zc-m-3 {
    margin-top: 12px;
}

.zc-m-4 {
    margin-top: 16px;
}

.zc-m-5 {
    margin-top: 20px;
}

/* Margin Bottom */
.zc-mb-1 {
    margin-bottom: 4px;
}

.zc-mb-2 {
    margin-bottom: 8px;
}

.zc-mb-3 {
    margin-bottom: 12px;
}

.zc-mb-4 {
    margin-bottom: 16px;
}

.zc-mb-5 {
    margin-bottom: 20px;
}

/* Margin Left */
.zc-ml-1 {
    margin-left: 4px;
}

.zc-ml-2 {
    margin-left: 8px;
}

.zc-ml-3 {
    margin-left: 12px;
}

.zc-ml-4 {
    margin-left: 16px;
}

.zc-ml-5 {
    margin-left: 20px;
}

/* Margin Right */
.zc-mr-1 {
    margin-right: 4px;
}

.zc-mr-2 {
    margin-right: 8px;
}

.zc-mr-3 {
    margin-right: 12px;
}

.zc-mr-4 {
    margin-right: 16px;
}

.zc-mr-5 {
    margin-right: 20px;
}

/* Own change */

.testingArea {
    background-color: rgb(178, 223, 219);
    font-family: "Poppins";
}

.border {
    border: none;
    margin: 0.2rem;
    box-shadow: 0 0px 2px 2px rgba(34, 41, 47, 0.007),
        0 1px 3px 2px rgba(67, 73, 78, 0.08);
}

.border-right {
    border: none;
    margin-top: 0px;
    margin-right: 1rem;
    box-shadow: 0 10px 5px 3px rgb(34 41 47 / 5%);
}

.border-bottom {
    border: none;
    -webkit-app-region: drag;
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 3px 1px #0808084f;
}

.no-drag {
    -webkit-app-region: no-drag;
}

#title-bar {
    -webkit-app-region: drag;
    height: 30px;
    padding: none;
    margin: 0px;
    font-family: "Poppins";
}

#title {
    position: fixed;
    top: 0px;
    left: 6px;
    font-family: "Poppins";
}

#title-bar-btns {
    -webkit-app-region: no-drag;
    position: fixed;
    top: 0px;
    right: 0px;
    font-family: "Poppins";
}

.window-button {
    height: 30px !important;
    line-height: 30px;
    padding: 0 0.5rem;
}

.window-button:hover {
    filter: brightness(85%);
}

.breadcrumb:before {
    color: #343434;
    font-size: 1.2em;
    font-family: "Poppins";
}

.breadcrumb,
.breadcrumb:last-child {
    color: #343434;
    font-size: 0.9em;
    font-family: "Poppins";
}

.breadcrumb:first-child {
    font-family: "Poppins";
}

input[type="file"] {
    display: none;
    font-family: "Poppins";
}

.zc-custom-file-upload {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.zc-sale-height {
    max-height: 550px;
    height: 550px;
    min-height: 550px;
    overflow-x: hidden !important;
}

.zc-sale-height-side {
    max-height: 140px;
    min-height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 10px;
}

.zc-golden-height {
    max-height: 350px;
    font-family: "Poppins";
    overflow-y: auto;
    overflow-x: hidden !important;
}

.zc-golden-height table {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-family: "Poppins";
    color: #37393b;
    margin: 10px 0 0 0;
    text-shadow: #fff 1px -1px 1px;
    text-align: left;
    width: 100%;
    border-collapse: collapse;
}

.zc-golden-height tbody tr td,
.zc-golden-height thead tr th {
    padding: 2px 6px;
    font-size: 12px !important;
    font-family: "Poppins";
}

/* For sale add scroll height media query */
@media screen and (max-width: 2600px) and (min-width: 1400px) {
    .zc-sale-height {
        min-height: 700px;
        max-height: 700px;
        height: 700px;
        font-family: "Poppins";
    }

    .zc-sale-height-side {
        min-height: 409px;
        max-height: 409px;
    }
}

@media screen and (max-width: 4600px) and (min-width: 2600px) {
    .zc-sale-height {
        min-height: 900px;
        max-height: 900px;
        height: 900px;
    }

    .zc-sale-height-side {
        min-height: 609px;
        max-height: 609px;
    }
}

/* For sale add page css */
.zvcr-row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.invoiceItemHeight {
    max-height: 480px !important;
    height: 480px;
    overflow: auto;
}

@media screen and (min-width: 1370px) {
    .invoiceItemHeight {
        max-height: 750px !important;
        height: 750px;
        overflow: auto;
    }
}

.zc_table2 {
    font-size: 10px;
    font-family: "Poppins";
}


.tableBorder {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    border: 1px solid #dedede;
    color: #37393b;
    margin: 10px 0 0 0;
    text-shadow: #fff 1px -1px 1px;
    text-align: left;
    width: 100%;
}

.tableBorder tbody tr td, .tableBorder thead tr th {
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    padding: 2px 6px;
    font-size: 12px !important;
}

.tableBorder .products td {
    border-bottom: none !important;
}

.invoiceItemHeight {
    max-height: 64vh !important;
    height: 64vh;
    overflow: auto;
}